"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var previouslyUsedUuidInsideReactRevolutionsPackage = {};
var uuidExecute = function () {
    var current = "".concat(Math.floor(new Date().valueOf() * Math.random()), "_").concat((((new Date().getTime()) % 1000) / 1000));
    if (undefined === previouslyUsedUuidInsideReactRevolutionsPackage[current]) {
        previouslyUsedUuidInsideReactRevolutionsPackage[current] = '';
        return current;
    }
    return uuidExecute();
};
exports.default = uuidExecute;
