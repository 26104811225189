"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var intervaller = null;
var timeouter = null;
var end = function (node) {
    clearTimeout(timeouter);
    node.style.width = '100vw';
    node.style.opacity = '0.77';
    timeouter = setTimeout(function () {
        node.style.transitionDuration = '0ms';
        node.style.width = '0px';
    }, 300);
};
exports.default = (function (currentProcess) {
    if (currentProcess === void 0) { currentProcess = true; }
    clearInterval(intervaller);
    var node = document.getElementsByClassName('top-loading-bar');
    if (node.length) {
        node = node[0];
        if (true === currentProcess) {
            clearTimeout(timeouter);
            var width_1 = 20;
            /**
             * Reset immediately
             */
            node.style.transitionDuration = '0ms';
            node.style.opacity = '0';
            node.style.width = '0px';
            node.style.zIndex = '-1';
            /**
             * Main animation start
             */
            node.style.opacity = '1';
            node.style.transitionDuration = '500ms';
            node.style.width = "".concat(width_1, "vw");
            node.style.zIndex = '1';
            /**
             * Animation "width" progress
             */
            intervaller = setInterval(function () {
                width_1 += 10;
                node.style.width = "".concat(width_1, "vw");
                if (80 <= width_1) {
                    clearInterval(intervaller);
                    // end(node);
                }
            }, 300);
        }
        else {
            end(node);
        }
    }
});
