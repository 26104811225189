"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = { searchType: '', data: [], searchText: '' }; }
    var searchType = state.searchType, data = state.data, searchText = state.searchText;
    if (action.payload) {
        searchType = action.payload.searchType;
        data = action.payload.payload;
        searchText = action.payload.searchText;
    }
    switch (action.type) {
        case 'search_global_SET': {
            state = {
                searchType: searchType,
                data: data,
                searchText: searchText
            };
            break;
        }
        case 'search_global_CLEAR': {
            state = {
                searchType: '',
                data: [],
                searchText: ''
            };
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
