"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = { searchType: '' }; }
    var searchType = state.searchType;
    if (action.payload) {
        searchType = action.payload.searchType;
    }
    switch (action.type) {
        case 'search_users_filter_SET': {
            state = {
                searchType: searchType
            };
            break;
        }
        case 'search_users_filter_CLEAR': {
            state = {
                searchType: ''
            };
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
