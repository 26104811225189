"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = null; }
    var value = localStorage.getItem(key);
    if (null !== value) {
        return JSON.parse(value);
    }
    else {
        if (null !== defaultValue) {
            defaultValue = JSON.stringify(defaultValue);
            localStorage.setItem(key, defaultValue);
            return defaultValue;
        }
    }
    return null;
});
