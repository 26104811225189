"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = []; }
    var type = action.type, payload = action.payload;
    if (payload) {
        payload = action.payload;
    }
    switch (type) {
        case 'available_food_nutrients_SET': {
            state = payload;
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
