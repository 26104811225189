"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    OK: 'OK',
    DUPLICATE: 'DUPLICATE',
    OCCASION_NOT_FOUND: 'OCCASION_NOT_FOUND',
    ALLERGEN_NOT_FOUND: 'ALLERGEN_NOT_FOUND',
    TYPE_NOT_FOUND: 'TYPE_NOT_FOUND',
    GOAL_NOT_FOUND: 'GOAL_NOT_FOUND',
    FOOD_NOT_FOUND: 'FOOD_NOT_FOUND',
    NO_MATCH: 'NO_MATCH',
    RECIPE_NOT_FOUND: 'RECIPE_NOT_FOUND',
    ASSIGMENT_NOT_FOUND: 'ASSIGMENT_NOT_FOUND',
    DIET_TEMPLATE_NOT_FOUND: 'DIET_TEMPLATE_NOT_FOUND',
    REQUIRED_FIELD_EMPTY: 'REQUIRED_FIELD_EMPTY',
    REQUIRED_FIELDS: 'REQUIRED_FIELDS',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    FILE_FILETYPE_NOT_SUPPORTED: 'FILE_FILETYPE_NOT_SUPPORTED',
    FILE_MAX_FILE_SIZE_REACHED: 'FILE_MAX_FILE_SIZE_REACHED',
    DUPLICATE_RECIPE_NAME: 'DUPLICATE_RECIPE_NAME',
    TERMS_NOT_ACCEPTED: 'TERMS_NOT_ACCEPTED',
    UNCONFIRMED: 'UNCONFIRMED',
    BASKET_EMAIL_INVALID: 'BASKET_EMAIL_INVALID',
    PRODUCT_NOT_AVAILABLE: 'PRODUCT_NOT_AVAILABLE',
    BASKET_EMPTY_ADDRESS: 'BASKET_EMPTY_ADDRESS',
    BASKET_NO_PRODUCTS: 'BASKET_NO_PRODUCTS',
    BASKET_INVALID_PAYMENT_METHOD: 'BASKET_INVALID_PAYMENT_METHOD',
    FILE_NOT_FOUND: 'FILE_NOT_FOUND',
    LOGIN_ATTEMPTS_EXCEEDED: 'LOGIN_ATTEMPTS_EXCEEDED',
    200: 200,
    FORM_CONTACT_ERROR_LENGTH_TITLE: 'FORM_CONTACT_ERROR_LENGTH_TITLE',
    FORM_CONTACT_ERROR_LENGTH_CONTENT: 'FORM_CONTACT_ERROR_LENGTH_CONTENT',
    LOGIN_REQUIRED: 'LOGIN_REQUIRED',
    DELETION_PROCESS: 'DELETION_PROCESS',
    PAYPAL_ERROR: 'PAYPAL_ERROR',
    PAYPAL_ALREADY_PAYED: 'PAYPAL_ALREADY_PAYED',
};
