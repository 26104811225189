"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (currentDateStr, daysToAdd) {
    if (daysToAdd === void 0) { daysToAdd = 0; }
    // Create a new Date object
    var currentDate = new Date(currentDateStr);
    // Add the specified number of days
    currentDate.setDate(currentDate.getDate() + daysToAdd);
    return currentDate;
});
