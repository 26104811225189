import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.default.css';
import controller_1 from '@symfony/ux-react/dist/render_controller.js';
import { Controller } from '@hotwired/stimulus';
export default {
  'symfony--ux-autocomplete--autocomplete': controller_0,
  'symfony--ux-react--react': controller_1,
  'symfony--ux-turbo--turbo-core': class extends Controller {
      constructor(context) {
          super(context);
          this.__stimulusLazyController = true;
      }
      initialize() {
          if (this.application.controllers.find((controller) => {
              return controller.identifier === this.identifier && controller.__stimulusLazyController;
          })) {
              return;
          }
          import('@symfony/ux-turbo/dist/turbo_controller.js').then((controller) => {
              this.application.register(this.identifier, controller.default);
          });
      }
  },
};