"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = { data: [], page: 1, total: 0 }; }
    var payload = action.payload ? action.payload : [];
    switch (action.type) {
        case 'exercise_search_SET': {
            state = payload;
            break;
        }
        case 'exercise_search_CLEAR': {
            state = {
                data: [],
                page: 0,
                total: 0,
            };
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
