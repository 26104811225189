"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
exports.default = (function (_a) {
    var props = __rest(_a, []);
    var initialActiveTab = props.initialActiveTab, tabTitle = props.tabTitle, tabContent = props.tabContent, displayAsPill = props.displayAsPill, label = props.label;
    var _b = (0, react_1.useState)(initialActiveTab), activeTab = _b[0], setActiveTab = _b[1];
    var handleTabChange = function (tabName) {
        setActiveTab(tabName);
    };
    if (undefined === displayAsPill) {
        displayAsPill = false;
    }
    return (react_1.default.createElement("div", null,
        label && react_1.default.createElement("p", { className: 'text-muted font-s font-bold my-3' },
            " ",
            label,
            " "),
        react_1.default.createElement("ul", { className: "nav nav-tabs" }, tabTitle.map(function (title) { return (react_1.default.createElement("li", { className: "nav-item m-1", key: "title-".concat(title.uid) },
            react_1.default.createElement("button", __assign({ className: 'nav-link no-wrap text-muted relative' }, (activeTab === title.uid) && {
                className: 'nav-link active no-wrap relative'
            }, { onClick: function () { return handleTabChange(title.uid); } }), title.label))); })),
        react_1.default.createElement("div", { className: "tab-content mt-3" }, tabContent.map(function (content) { return (react_1.default.createElement("div", __assign({ className: 'tab-pane' }, (activeTab === content.uid) && {
            className: 'tab-pane active'
        }, { key: "content-".concat(content.uid) }), content.content)); }))));
});
