"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (element, addYAxisPixel) {
    if (element === void 0) { element = null; }
    if (addYAxisPixel === void 0) { addYAxisPixel = 0; }
    if (!element) {
        return false;
    }
    try {
        var rect = element.getBoundingClientRect();
        return rect.top + addYAxisPixel <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left >= 0 &&
            rect.bottom >= 0 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
    catch (e) {
        return false;
    }
});
