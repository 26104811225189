"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = { data: [] }; }
    var payload = action.payload, type = action.type;
    switch (type) {
        case 'diet_template_SET': {
            state = {
                data: payload.data,
                fetched: true,
            };
            return state;
        }
        case 'diet_template_CLEAR': {
            state = {
                data: [],
                fetched: false,
            };
            return state;
        }
        default: {
            return state;
        }
    }
});
