"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreGetInputValue = exports.StoreAddMultiple = exports.StoreReplace = exports.StoreUnshift = exports.StoreSet = exports.StoreClear = exports.StoreRead = void 0;
var __1 = require("..");
var StoreRead = function (name) {
    var GlobalStore = __1.store.getState();
    return GlobalStore[name];
};
exports.StoreRead = StoreRead;
var StoreClear = function (name) {
    __1.store.dispatch({ type: "".concat(name.toLowerCase(), "_CLEAR") });
};
exports.StoreClear = StoreClear;
var StoreSet = function (name, payload) {
    __1.store.dispatch({ type: "".concat(name.toLowerCase(), "_SET"), payload: payload });
};
exports.StoreSet = StoreSet;
var StoreUnshift = function (name, payload) {
    __1.store.dispatch({ type: "".concat(name.toLowerCase(), "_UNSHIFT"), payload: payload });
};
exports.StoreUnshift = StoreUnshift;
var StoreReplace = function (name, payload) {
    __1.store.dispatch({ type: "".concat(name.toLowerCase(), "_REPLACE"), payload: payload });
};
exports.StoreReplace = StoreReplace;
var StoreAddMultiple = function (name, payload) {
    __1.store.dispatch({ type: "".concat(name.toLowerCase(), "_ADD_MULTIPLE"), payload: payload });
};
exports.StoreAddMultiple = StoreAddMultiple;
/**
 * Read input value typed on search page
 *
 * Example: store_name (search_filter), parent_store_name (food_search)
 *
 *      search_filter : {
 *          food_search: 'Algen, agar, roh'
 *      }
 */
var StoreGetInputValue = function (store_name, parent_store_name) {
    var GlobalStore = __1.store.getState();
    var data = GlobalStore[store_name];
    var item = data.filter(function (d) { return d.parent_store_name === parent_store_name; });
    if (0 !== item.length) {
        return item[0].input_value;
    }
    return '';
};
exports.StoreGetInputValue = StoreGetInputValue;
