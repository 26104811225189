"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = []; }
    var data = action.payload ? action.payload : [];
    switch (action.type) {
        case 'recipe_search_SET': {
            state = data;
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
