"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var defaultBasketState = {
    billing_information: {
        'title': '',
        'salutation': '',
        'firstname': '',
        'lastname': '',
        'city': '',
        'zip': '',
        'street': '',
        'building_number': '',
        'phone': '',
        'email': '',
        'country': '',
    },
    delivery_information: {
        'title': '',
        'salutation': '',
        'firstname': '',
        'lastname': '',
        'city': '',
        'zip': '',
        'street': '',
        'building_number': '',
        'phone': '',
        'email': '',
        'country': '',
    },
    items: [],
    pay_method: '',
    loaded: false,
};
exports.default = (function (state, action) {
    if (state === void 0) { state = defaultBasketState; }
    var type = action.type, payload = action.payload;
    if ((0, lodash_1.isObject)(payload)) {
        payload = action.payload;
    }
    switch (type) {
        case 'basket_SET': {
            state = payload;
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
