"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Get single url element from url
 * return the 1 catched item
 */
exports.default = (function (key) {
    var currentHref = decodeURIComponent(window.location.href).split('?');
    if (1 >= currentHref.length) {
        return null;
    }
    var search = currentHref[1];
    var keyValues = search.split('&');
    var match = '';
    for (var x = 0; x < keyValues.length; x++) {
        if (key === keyValues[x].substring(0, key.length)) {
            match = keyValues[x];
        }
    }
    if ('' === match) {
        return null;
    }
    var keyValue = match.split('=');
    if (2 !== keyValue.length) {
        return null;
    }
    return keyValue[1];
});
