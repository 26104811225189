"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    WEBSITE: {
        sidebar: {
            width: 250
        }
    },
    BREAKPOINTS: {
        zero: 0,
        sm: 576,
        md: 768,
        default: 992,
        compact: 1400,
        large: 1800,
        adminLte: {
            md: 991
        }
    },
    SVG: {
        COLOR: {
            WHITE: 'rgb(255,255,255)',
            RED: '#FF4456',
            DARK: 'rgb(55,55,55)',
            HEADER_ITEM: '#385898#',
            GRAY: '#c2c7d0',
            PRIMARY: 'rgb(142, 199, 65)',
        },
        WIDTH: {
            XXXXXS: 6,
            XXXXS: 8,
            XXXS: 10,
            XXS: 12,
            XS: 16,
            S: 22,
            M: 24,
            L: 26,
            XL: 32,
            XXL: 45,
            CUSTOM_64: 64,
            CUSTOM_84: 84,
            CUSTOM_128: 128
        }
    }
};
