"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function () {
    var _a, _b, _c, _d, _e, _f;
    // Define ranges for warm colors in HSV color space
    var hue = Math.floor(Math.random() * 60) + 20; // Hue range: 20-80 (yellow to red)
    var saturation = Math.floor(Math.random() * 50) + 50; // Saturation range: 50-100
    var value = Math.floor(Math.random() * 50) + 50; // Value range: 50-100
    // Convert HSV to RGB
    var chroma = value * saturation / 100;
    var huePrime = hue / 60;
    var x = chroma * (1 - Math.abs(huePrime % 2 - 1));
    var r1, g1, b1;
    if (huePrime >= 0 && huePrime <= 1) {
        _a = [chroma, x, 0], r1 = _a[0], g1 = _a[1], b1 = _a[2];
    }
    else if (huePrime >= 1 && huePrime <= 2) {
        _b = [x, chroma, 0], r1 = _b[0], g1 = _b[1], b1 = _b[2];
    }
    else if (huePrime >= 2 && huePrime <= 3) {
        _c = [0, chroma, x], r1 = _c[0], g1 = _c[1], b1 = _c[2];
    }
    else if (huePrime >= 3 && huePrime <= 4) {
        _d = [0, x, chroma], r1 = _d[0], g1 = _d[1], b1 = _d[2];
    }
    else if (huePrime >= 4 && huePrime <= 5) {
        _e = [x, 0, chroma], r1 = _e[0], g1 = _e[1], b1 = _e[2];
    }
    else if (huePrime >= 5 && huePrime <= 6) {
        _f = [chroma, 0, x], r1 = _f[0], g1 = _f[1], b1 = _f[2];
    }
    var m = value - chroma;
    var r = Math.round((r1 + m) * 255);
    var g = Math.round((g1 + m) * 255);
    var b = Math.round((b1 + m) * 255);
    // Convert RGB to hexadecimal
    var hexColor = '#' + ("0" + r.toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + b.toString(16)).slice(-2);
    return hexColor;
});
