"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'search_filter_SET': {
            var _a = action.payload, storeName_1 = _a.storeName, input_value = _a.input_value;
            var item = state.filter(function (d) { return d.storeName === storeName_1; });
            if (0 !== item.length) {
                for (var x = 0; x < state.length; x++) {
                    if (state[x].storeName === storeName_1) {
                        state[x].input_value = input_value;
                        break;
                    }
                }
            }
            else {
                state.push({ storeName: storeName_1, input_value: input_value });
            }
            return state;
        }
        default: {
            return state;
        }
    }
});
