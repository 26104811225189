"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PropsCheck = function (array, props, state) {
    if (array === void 0) { array = []; }
    if (props === void 0) { props = {}; }
    if (state === void 0) { state = {}; }
    var update = false;
    array.map(function (keyName) {
        if (state[keyName] !== props[keyName]) {
            update = true;
        }
    });
    return update;
};
exports.default = PropsCheck;
