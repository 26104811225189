"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (object) {
    if (typeof {} !== typeof object || null === object || undefined === object || '[object Object]' !== Object.prototype.toString.call(object)) {
        return false;
    }
    var keys = [];
    try {
        keys = Object.getOwnPropertyNames(object);
    }
    catch (e) {
        return false;
    }
    return true;
});
