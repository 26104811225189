"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DndReorder = void 0;
/**
 * A little function to help us with reordering the result
 */
var DndReorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
exports.DndReorder = DndReorder;
