"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
exports.default = (function (_a) {
    var props = __rest(_a, []);
    var _b = (0, react_1.useState)(''), data = _b[0], setData = _b[1];
    var callback = props.callback, callbackProps = props.callbackProps, textarea = props.textarea, placeholder = props.placeholder, name = props.name, readonly = props.readonly, maxMessageLength = props.maxMessageLength, textareaSize = props.textareaSize;
    (0, react_1.useEffect)(function () {
        setData(props.data);
    }, []);
    function keyUp(e) {
        var isEnter = e.key === 'Enter' || e.key === 'NumpadEnter';
        if (isEnter && callback) {
            (callback)(data, callbackProps, true);
        }
    }
    function change(e) {
        var value = e.target.value.substring(0, 0 < maxMessageLength ? maxMessageLength : 255);
        if (callback) {
            (callback)(value, callbackProps);
        }
    }
    var module = null;
    if (textarea) {
        module = (react_1.default.createElement("textarea", __assign({ className: 'form-control border-radius-10r p-3', onChange: function (e) { return change(e); }, onKeyUp: function (e) { return keyUp(e); }, defaultValue: data }, (readonly) && {
            readonly: 'readonly'
        }, { placeholder: placeholder, rows: textareaSize })));
    }
    else {
        module = (react_1.default.createElement("input", __assign({ className: 'form-control border-radius-10r', type: "text" }, (['password', 'password2'].includes(name)) && {
            type: 'password'
        }, (['number'].includes(name)) && {
            type: 'number'
        }, { onChange: function (e) { return change(e); }, onKeyUp: function (e) { return keyUp(e); }, defaultValue: data, name: name }, (!['email', 'password', 'password2'].includes(callbackProps)) && {
            autoComplete: "off"
        }, (readonly) && {
            readonly: 'readonly'
        }, { placeholder: placeholder })));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        module,
        0 < maxMessageLength &&
            react_1.default.createElement("p", { className: 'text-right text-muted' }, "".concat(data.length, "/").concat(maxMessageLength))));
});
