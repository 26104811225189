"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (state, action) {
    if (state === void 0) { state = { data: [] }; }
    var type = action.type, payload = action.payload;
    if (payload) {
        payload = action.payload.data;
    }
    switch (type) {
        case 'chat_SET': {
            state = {
                data: payload
            };
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});
