"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (array) {
    if (undefined === array || null == array || '[object Array]' !== Object.prototype.toString.call(array)) {
        return false;
    }
    try {
        if (0 !== array.length) {
            for (var x = 0; x <= array.length - 1; x++) {
                array[x];
                break;
            }
        }
    }
    catch (e) {
        return false;
    }
    return true;
});
